






























































import { Observer } from "mobx-vue";
import { Component, Vue, Provide, Ref, Inject } from "vue-property-decorator";
import { MysteryBoxViewModel } from "../viewmodels/mystery-box-viewmodel";
import MonsterCard from "@/modules/egg-hatch/components/monster-card.vue";

@Observer
@Component({
  components: {
    MonsterCard,
  },
})
export default class SummoneddBoxDialog extends Vue {
  @Inject() vm!: MysteryBoxViewModel;

  character: any = {
    ID: 12,
    Class: "Wood",
    Grade: 2,
    Level: 1,
    Race: "Wood",
    AvatarURL: "https://d1c76uopd5gurh.cloudfront.net/Wood/_png/4_3_1.0000.png",
  };

  // show = true;
}
